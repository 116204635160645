<template>
	<div data-component="error">
		<div class="page__info">
			<div class="page__info--title">
				Page not found
			</div>
		</div>
		<div data-element="main">
			<p>This page can't be found.</p>
			<V2Button
				size="xl"
				to="/"
				fit
			>
				Go back to the home page
			</V2Button>
		</div>
	</div>
</template>

<script>
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		components: {
			V2Button
		},
		data: () => ({
		}),
		computed: {
		},
		created () {
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>
.page__info--title {
  font-size: 48px;
  line-height: 52px;
}

	[data-component='error'] {
		[data-element='main'] {
			p {
        @include font(18px, $c-dark-navy, 400, 20px);
				margin:12px 0 rem(32);
			}

		}
	}
</style>
